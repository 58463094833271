<template>
  <Lois></Lois>
</template>

<script>
import Lois from "../components/Lois.vue";

export default {
  name: "Loisir",
  components: {
    Lois,
  },
};
</script>

<style scoped></style>
